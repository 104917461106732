<template>
  <div class="content-container pt-0">
    <div class="landing-header">
        <img class="img-center" src="../assets/images/landing-banner.png" alt="">
    </div>

    <div class="landing-header-title align-end gray-100 mb-0">
      <div class="container mlr-20 flex flex-col flex-auto max-w1440">
        <h1 class="xl">Building <br>Blocks</h1>
        <p class="mt-0">
          Building blocks are the reusable functional patterns that build State
          of Ohio user interfaces.
        </p>
      </div>
    </div>

    <!-- <section>
      <div class="content-band mt-20 mb-10">
        <h2 class="">What are Building Blocks?</h2>
        <p>
          Building blocks are the reusable functional patterns that build State
          of Ohio user interfaces.
        </p>
      </div>
    </section> -->

    <section>
      <div class="content-band mt-10 flex-grid gap-20">
        <NewTile
          v-for="(element, index) in tileData"
          :key="index"
          :tile="element"
          page="building-blocks"
        />
      </div>
    </section>
  </div>
</template>

<script>
import NewTile from "../components/NewTile";
export default {
  name: "PhilosophyStandards",
  data: function() {
    return {
      tileData: [
        {
          icon: "components",
          title: "Components",
          description:
            "The smallest functional elements or patterns that have value to the user.",
        },
        {
          icon: "blocks",
          title: "Blocks",
          description:
            "Reusable combinations of components that represent specific interactions.",
        },
        {
          icon: "views",
          title: "Views",
          description:
            "Fixed combinations of components and blocks that represent specific functions or create content bands.",
        },
        {
          icon: "pages",
          title: "Pages",
          description:
            "Fixed combinations of components, blocks, and views that represent specific functions or purpose.",
        },
      ],
    };
  },
  components: {
    NewTile,
  },
};
</script>
